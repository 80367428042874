
// Fonts
@import url('https://fonts.googleapis.com/css?family=montserrat|Raleway:300,400,600');


// Variables
@import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap.scss";

html, body { width:100%;height:100%;}
#app {
	position: relative;
}
body { font-family: 'montserrat', sans-serif; }
.extraPadding { margin-left: 1rem; margin-right: 1.8rem; }
.navbar-toggler, .navbar-toggler:focus { outline: none; border: none; }
#bmenu { height: 30px; margin-top: 2px; }
#langSwitch { color: #950565; position: absolute; top:0px; right: 75px; font-size: 2.5rem; font-weight: 100; text-transform: uppercase; }

#topLogo { height: 28px; }

.bgImg {
	z-index: -10;	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  
	background-attachment: fixed;
  background-position: 50% 50%;
  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;	
}

.vhide { visibility: hidden; }
.vshow { visibility: visible; }
.img-resp { max-width: 100%; }
.mobiImg { width: 100%; max-width:250px; }

.eimg { max-width: 100%; }

.etxt { font-weight: bold; text-transform: uppercase; padding-top: 0.4rem; }
.eselect { margin-top: 2rem; }

.tplHead { font-weight: 900; font-size: 10rem; margin: 0; padding: 0;	text-transform: uppercase; margin: 25px 0;  }
.tplHead2, .tplHead2White { font-weight: 900; font-size: 2rem; margin: 0; padding: 0;	text-transform: uppercase; margin: 25px 0; }
.tplHead2White { color: #fff; }

.tplText, .tplTextWhite {  font-weight:100; font-size: 0.9rem; line-height: 1.2rem; }
.tplTextWhite { color: #fff; }
.tplText2, .tplText2White { font-size: 0.9rem; line-height: 1.1rem; }
.tplText2White { color: #fff; }
.spacer { margin-bottom: 25px; }

.tplEpisodeNo { color: #fff; font-size: 14rem; line-height: 14rem; margin: 0; padding: 0; margin-top: 5.3rem;  }
.tplEpisodeUL { padding: 0; padding-left: 1rem;  }
.epLinkArea { font-size: 2rem; margin: 0; padding: 0; }

.mobiAppIcon { margin-top: 1rem; max-height: 55px; max-width: 100%; }
#tpl2HeadImage { position: absolute; top:-108px; z-index: -15; }

.bgAni {
	visibility: hidden;		
	opacity: 0;	
	animation: 800ms fxShowHide infinite;				
}
.bgAniSlow {
	visibility: hidden;
	opacity: 1;
	animation: 5000ms fxOctosenso infinite;
}

@keyframes fxOctosenso {
  0%   { visibility: hidden;  opacity: 0; }
	20%  { visibility: visible; opacity: 1; }	
	21%  { visibility: hidden;  opacity: 0; }
	100% { visibility: hidden;  opacity: 0; }
}

@keyframes fxShowHide {
  0%   { visibility: hidden;  opacity: 0; }
	25%  { visibility: visible; opacity: 1; }
	50%  { visibility: hidden;  opacity: 0; }
	100% { visibility: hidden;  opacity: 0; }
}

#startImg {
	position: absolute;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	max-width: 351px;
	width: 90%;

	z-index: -8;
}
#collapsingNavbar { background: rgba(240,240,240,0.9); }

#collapsingNavbar {
    /* position: absolute;
    z-index: 10;
    right: 0rem;
    width: 100%;
		top: 3rem; */
}

nav{z-index:10;}
.navbar-inverse .navbar-nav .nav-link { color: #000; }
.navbar-inverse .navbar-nav .nav-link:hover { color: #555; }
.nav-link { 
	font-weight: bold;
	margin: 0; padding: 0;	
	line-height: 4rem; font-size: 4rem; 
	line-height: 6rem; font-size: 6rem; 
	
	text-transform: uppercase;
}
.subnav-link { 
	font-weight: normal;
	margin: 0; padding: 0;	
	font-size: 1.7rem; 
	margin-right: 1.5rem;	
	
	text-transform: uppercase;
}

#newsMenu {
	position: absolute;
	bottom: 1.5rem;
	left: 1rem;
}

.conceptPage {
	text-align:right;
}
#imgFilter {
	background: rgba(0,0,0,0.2);
	z-index: -9;
}

@media (max-width:900px) {
	.tplHead { font-size: 8rem;  }
}
@media (max-width:768px) {
	.tplHead { font-size: 6.5rem;  }
	.episodeTitle { font-size: 1.5rem; }
	.nav-link { line-height: 5rem; font-size: 5rem; }
}

@media (max-width:601px) {
	.tplHead { font-size: 3rem; }
	.nav-link { line-height: 4rem; font-size: 4rem; }
	.conceptPage {
		text-align: left;
	}
	.etxt { font-size: 0.9rem; }
	#newsMenu {
		position: initial;
		margin-left: 1rem;
		margin-top: 1rem;
	}
	.subnav-link {
		font-size: 1.2rem;
		margin-right: 0.5rem;
	}
}

@media (max-width:500px) {	
	.episodeTitle { font-size: 1.1rem; }
	.etxt { font-size: 0.8rem; }
	.nav-link { line-height: 3rem; font-size: 3rem; }
}

@media (max-width:450px) {		
	.etxt { font-size: 0.7rem; }
}

@media (max-width:380px) {		
	.etxt { font-size: 0.55rem; }
	.nav-link { line-height: 2rem; font-size: 2rem; }
}
