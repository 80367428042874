
// Body
$body-bg: #fff;

// Typography
// $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
// $font-family-sans-serif: "Raleway", sans-serif;
// $font-size-base: 8rem;
// $line-height-base: 1.6;
$text-color: #000;
$link-color: #e5007d;
$link-hover-color: #a0a0a0;
$link-hover-decoration: none;

$navbar-inverse-color: $link-color;
$navbar-light-color: $navbar-inverse-color;
